import React, { useEffect, useState } from 'react';
import { Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, _ } from "gridjs-react";
import RbAlert from 'react-bootstrap/Alert';
import axios from 'axios';
import { verifyToken } from '../Common/AuthToken';
import { FaEdit, FaTrashAlt } from "react-icons/fa";

const Processes = () => {
  const [modal, setModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [source, setSource] = useState('');
  const [destination, setDestination] = useState('');
  const [action, setAction] = useState('');
  const [error, setError] = useState('');
  const [userData, setUserData] = useState([]);
  const [cachedData, setCachedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const storedToken = localStorage.getItem('auth_token');
  const [processData, setProcessData] = useState([]);
  const [scheduledProcessData, setScheduledProcessdata] =  useState([]);
  const [processErrorMsg, setProcessErrorMsg] = useState("");
  const [showProcessError, setShowProcessError] = useState(false);
  const [fileTransferData, setFileTransferData] = useState([]);
  const [fileSearchError, setFileSearchError] = useState("");
  const [fileSearchQuery, setFileSearchQuery] = useState("");
  const [showFileResults, setShowFileResults] = useState(false);
  const [scheduledDate, setScheduledDate] = useState('');
  const [scheduledTime, setScheduledTime] = useState('');
  const [repeat, setRepeat] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState('');
  const [isScheduling, setIsScheduling] = useState(false);

  const toggleModal = () => setModal(!modal);
  const toggleScheduleModal = () => setScheduleModal(!scheduleModal);
  const toggleEditModal = () => setEditModal(!editModal);

  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        slots.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return slots;
  };

  const getRepeatString = (repeatValue) => {
    switch (repeatValue) {
      case 0:
        return 'Daily';
      case 1:
        return 'Weekly';
      case 2:
        return 'Monthly';
      default:
        return 'No Repeat';
    }
  };

  const getProcessStatusString = (statusValue) => {
    switch (statusValue) {
      case 0:
        return 'Scheduled';
      case 1:
        return 'Completed';
      case 2:
        return 'Failed';
      case 3:
        return 'Cancelled';
      default:
        return 'Unknown';
    }
  };
  const timeSlots = generateTimeSlots();

  const updateCache = async () => {
    try {
      const config = {
        method: 'POST',
        url: '/api/allProjects',
        headers: { 'Content-Type': 'application/json' },
        data: { user_email: userData[0]?.email || "" },
      };

      const response = await axios.request(config);
      const projectOptions = response.data.data.map((project) => (
        <option key={project.uniqueId.toLowerCase()} value={project.uniqueId.toLowerCase()}>
          {project.projectName}
        </option>
      ));
      setCachedData([
        <option key="" value="">
          Select a Project
        </option>,
        ...projectOptions,
      ]);
    } catch (error) {
      console.error('Error updating cache:', error);
    }
  };

  useEffect(() => {
    const fetchProcessData = async () => {
      try {
        const config = {
          method: 'GET',
          url: `/api/getProcessData`,
          headers: {
            'Authorization': `Bearer ${storedToken}`,
            'Content-Type': 'application/json',
          },
        };

        const response = await axios.request(config);

        if (response.data.status === 1) {
          const processDataArray = response.data.data
            .map((process) => [
              process.processId,
              process.userEmail,
              process.startTime,
              process.endTime,
              process.transferSize,
              process.numberOfFiles,
              process.projectId
            ])
            .sort((a, b) => new Date(b[2]) - new Date(a[2]));

          setProcessData(processDataArray);
        } else {
          toast.error("Failed to fetch process data.");
          setShowProcessError(true);
          setProcessErrorMsg("Failed to fetch process data");
        }
      } catch (error) {
        console.error('Error fetching process data:', error);
        toast.error("Error fetching process data.");
        setShowProcessError(true);
        setProcessErrorMsg("Error fetching process data.");
      }
    };

    fetchProcessData();
  }, [storedToken]);

  useEffect(() => {
    const fetchScheduledProcessData = async () => {
      try {
        const config = {
          method: 'GET',
          url: `/api/getScheduledProcessData`,
          headers: {
            'Authorization': `Bearer ${storedToken}`,
            'Content-Type': 'application/json',
          },
        };

        const response = await axios.request(config);

        if (response.data.status === 1) {
          const scheduledProcessDataArray = response.data.data
            .map((process) => [
            process.processId,
            process.userEmail,
            process.source,
            process.destination,
            process.action,
            process.scheduledDateTime
              ? new Date(process.scheduledDateTime).toLocaleString('en-GB', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                })
              : 'Invalid Date',
            getRepeatString(process.repeat),
            getProcessStatusString(process.processStatus),
            ])
            .sort((a, b) => new Date(b[2]) - new Date(a[2]));

          setScheduledProcessdata(scheduledProcessDataArray);
        } else {
          toast.error("Failed to fetch process data.");
        }
      } catch (error) {
        console.error('Error fetching scheduled process data:', error);
        toast.error("Error fetching scheduled process data.");
      }
    };

    fetchScheduledProcessData();
  }, [storedToken]);

  const handleFileSearch = async () => {
    try {
      const config = {
        method: 'GET',
        url: `/api/getFileTransferData?processId=${fileSearchQuery}`,
        headers: {
          'Authorization': `Bearer ${storedToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios.request(config);

      if (response.data.status === 1) {
        const fileDataArray = response.data.data.map((file) => [
          file.processId,
          file.fileId,
          file.fileName
        ]);
        setFileTransferData(fileDataArray);
        setShowFileResults(true);
      } else {
        toast.error("Failed to fetch file transfer data.");
        setFileSearchError("Failed to fetch file transfer data");
        setShowFileResults(false);
      }
    } catch (error) {
      console.error('Error fetching file transfer data:', error);
      toast.error("Error fetching file transfer data.");
      setFileSearchError("Error fetching file transfer data.");
      setShowFileResults(false);
    }
  };

  const handleEditClick = (row) => {
    const process = {
        processId: row.cells[0].data,
        userEmail: row.cells[1].data,
        source: row.cells[2].data,
        destination: row.cells[3].data,
        action: row.cells[4].data,
        scheduledDateTime: row.cells[5].data,
        repeat: row.cells[6].data,
        processStatus: row.cells[7].data,
    };

    setSelectedProcess(process);

    if (process.scheduledDateTime && !isNaN(new Date(process.scheduledDateTime))) {
        const dateObj = new Date(process.scheduledDateTime);
        setScheduledDate(dateObj.toISOString().split('T')[0]);
        setScheduledTime(dateObj.toTimeString().slice(0, 5));
    } else {
        setScheduledDate('');
        setScheduledTime('');
    }

    setRepeat(process.repeat || '');
    toggleEditModal();
};


  const handleUpdateScheduledProcess = async () => {
    if (!scheduledDate || !scheduledTime || repeat === null) {
      toast.error('Please fill in all fields.');
      return;
    }

    setIsLoading(true);

    try {
      const config = {
        method: 'POST',
        url: '/api/updateScheduledProcess',
        headers: { 'Content-Type': 'application/json' },
        data: {
          processId: selectedProcess.processId,
          scheduledDate,
          scheduledTime,
          repeat: parseInt(repeat, 10)
        },
      };

      await axios.request(config);
      toast.success('Scheduled process updated successfully.');
      setIsLoading(false);
      toggleEditModal();
    } catch (error) {
      console.error('Error updating scheduled process:', error);
      toast.error('Failed to update the process.');
      setIsLoading(false);
    }
  };

  const handleCancelProcess = async (row) => {
    if (!window.confirm('Are you sure you want to cancel this scheduled process?')) return;

    setIsLoading(true);

    const processId = row.cells[0].data;
    const processStatus = row.cells[7].data; // Assuming the process status is in the 8th column

    // Prevent cancellation if the process is already cancelled
    if (processStatus === "Cancelled") {
        toast.warning("This process is already cancelled.");
        setIsLoading(false);
        return;
    }

    try {
        const config = {
            method: 'POST',
            url: '/api/cancelScheduledProcess',
            headers: { 'Content-Type': 'application/json' },
            data: { processId }
        };

        const response = await axios.request(config);

        // Handle API response
        if (response.data.message === "Process is already cancelled") {
            toast.warning("This process was already cancelled.");
        } else {
            toast.success("Scheduled process cancelled successfully.");
        }

        setIsLoading(false);
    } catch (error) {
        console.error("Error canceling scheduled process:", error);

        if (error.response?.data?.message === "Process is already cancelled") {
            toast.warning("This process was already cancelled.");
        } else {
            toast.error("Failed to cancel the process.");
        }

        setIsLoading(false);
    }
};



  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = { token: storedToken };
        const tokenResponse = await verifyToken(user);
        setUserData([tokenResponse?.data]);
      } catch (error) {
        console.error('Error verifying token:', error);
        toast.error('Error fetching user data. Please try again.');
      }
    };

    fetchData();
  }, [storedToken]);

  useEffect(() => {
    if (userData.length) {
      updateCache();
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!source || !destination || !action) {
      setError('Please fill in all fields.');
      return;
    }

    setError('');

    setModal(false);

    setIsLoading(true);

    try {
      const config = {
        method: 'POST',
        url: '/api/newRcloneProcess',
        headers: { 'Content-Type': 'application/json' },
        data: {
          source,
          destination,
          action: action.toLowerCase(),
          user_email: userData[0]?.email || "",
        },
      };

      const response = await axios.request(config);
      const uniqueId = response.data.uniqueId;

      setTimeout(() => {
        toast.success(
          `Your request with process ID: ${uniqueId} has been completed. Refresh the webpage to see the process details.`
        );
        setIsLoading(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting file transfer process:', error);
      toast.error('Failed to start the process. Please try again.');
      setIsLoading(false);
    }
  };

  const handleScheduleSubmit = async (e) => {
    e.preventDefault();

    if (!source || !destination || !action || !scheduledDate || !scheduledTime) {
        setError('Please fill in all fields for scheduling.');
        return;
    }

    setError('');
    setScheduleModal(false);
    setIsScheduling(true); // Set scheduling in progress

    const repeatFrequencyMap = {
        daily: 0,
        weekly: 1,
        monthly: 2,
    };

    const repeatValue = repeatFrequencyMap[repeat] ?? -1;

    try {
        const config = {
            method: 'POST',
            url: '/api/scheduleRcloneProcess',
            headers: { 'Content-Type': 'application/json' },
            data: {
                source,
                destination,
                action: action.toLowerCase(),
                user_email: userData[0]?.email || "",
                schedule_date: scheduledDate,
                schedule_time: scheduledTime,
                repeat: repeatValue,
            },
        };

        const response = await axios.request(config);
        const uniqueId = response.data.processId;

        setTimeout(() => {
            toast.success(`Your scheduled request with process ID: ${uniqueId} has been set.`);
            setIsScheduling(false);
        }, 5000);
    } catch (error) {
        console.error('Error scheduling file transfer process:', error);
        toast.error('Failed to schedule the process. Please try again.');
        setIsScheduling(false);
    }
};

  const scheduledProcessColumns = [
    "Process Id",
    "User Email",
    "Source",
    "Destination",
    "Action",
    "Scheduled for date & time",
    "Repeat",
    "Process Status",
    {
      name: "Actions",
      formatter: (cell, row) => {
          return _(
              <>
                  <FaEdit
                      size={18}
                      style={{ cursor: "pointer", color: "#ffc107", marginRight: "10px" }}
                      onClick={() => handleEditClick(row)}
                      title="Edit"
                  />
                  <FaTrashAlt
                      size={18}
                      style={{ cursor: "pointer", color: "#dc3545" }}
                      onClick={() => handleCancelProcess(row)}
                      title="Cancel"
                  />
              </>
          );
      }
  }
  ];
  const processColumns = [
    "Process ID",
    "User Email",
    "Start Time",
    "End Time",
    "Transfer Size",
    "Number of Files",
    "Project ID",
  ];


  const fileColumns = [
    "Process ID",
    "File ID",
    "File Name",
  ];


  return (
    <div>
      <h5 className="fw-bold mb-3">File Transfer</h5>
      <div className="mb-3">
        <Button color="primary" onClick={toggleModal}>
          Start File Transfer
        </Button>
      </div>

      {(isLoading || isScheduling) && (
    <div className="text-center my-3">
        <Spinner color="primary" />
        <p>{isScheduling ? "Scheduling in progress..." : "Transfer is in progress..."}</p>
    </div>
)}
      <h5 className="fw-bold mb-3">Scheduled processes</h5>
      <div className="d-flex mb-3">
        <Input type="text" placeholder="Search scheduled processes" className="me-2" style={{ width: "300px" }} />
        <Button color="primary" onClick={toggleScheduleModal} className="ms-2">
          Schedule File Transfer
        </Button>
      </div>

      <Grid
        columns={scheduledProcessColumns}
        data={scheduledProcessData}
        sort
        pagination={{
          enabled: true,
          limit: 10,
        }}
      />

      {/* Edit Scheduled Process Modal */}
      <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit Scheduled Process</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="scheduledDate">Scheduled Date</Label>
              <Input type="date" id="scheduledDate" value={scheduledDate} onChange={(e) => setScheduledDate(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="scheduledTime">Scheduled Time</Label>
              <Input type="select" id="scheduledTime" value={scheduledTime} onChange={(e) => setScheduledTime(e.target.value)}>
                <option value="">Select Time Slot</option>
                {timeSlots.map((slot) => (
                  <option key={slot} value={slot}>{slot}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="repeat">Repeat</Label>
              <Input type="select" id="repeat" value={repeat} onChange={(e) => setRepeat(e.target.value)}>
                <option value="">Select Repeat Frequency</option>
                <option value="0">Daily</option>
                <option value="1">Weekly</option>
                <option value="2">Monthly</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateScheduledProcess}>Update</Button>
          <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Start File Transfer</ModalHeader>
        <ModalBody>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="source">Source</Label>
              <Input
                type="text"
                id="source"
                placeholder="Enter source path"
                value={source}
                onChange={(e) => setSource(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="destination">Destination</Label>
              <Input
                type="select"
                id="destination"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
              >
                {cachedData}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="action">Action</Label>
              <Input
                type="select"
                id="action"
                value={action}
                onChange={(e) => setAction(e.target.value)}
              >
                <option value="">Select an Action</option>
                <option value="Copy">Copy</option>
                <option value="Move">Move</option>
                <option value="Sync">Sync</option>
              </Input>
            </FormGroup>
            <ModalFooter>
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>

      {/* Schedule File Transfer Modal */}
      <Modal isOpen={scheduleModal} toggle={toggleScheduleModal}>
        <ModalHeader toggle={toggleScheduleModal}>Schedule File Transfer</ModalHeader>
        <ModalBody>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleScheduleSubmit}>
            <FormGroup>
              <Label for="source">Source</Label>
              <Input
                type="text"
                id="source"
                placeholder="Enter source path"
                value={source}
                onChange={(e) => setSource(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="destination">Destination</Label>
              <Input
                type="select"
                id="destination"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
              >
                {cachedData}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="action">Action</Label>
              <Input
                type="select"
                id="action"
                value={action}
                onChange={(e) => setAction(e.target.value)}
              >
                <option value="">Select an Action</option>
                <option value="Copy">Copy</option>
                <option value="Move">Move</option>
                <option value="Sync">Sync</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="scheduledDate">Scheduled Date</Label>
              <Input
                type="date"
                id="scheduledDate"
                value={scheduledDate}
                onChange={(e) => setScheduledDate(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="scheduledTime">Scheduled Time</Label>
              <Input
                type="select"
                id="scheduledTime"
                value={scheduledTime}
                onChange={(e) => setScheduledTime(e.target.value)}
              >
                <option value="">Select Time Slot</option>
                {timeSlots.map((slot) => (
                  <option key={slot} value={slot}>{slot}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="repeat">Repeat</Label>
              <Input
                type="select"
                id="repeat"
                value={repeat}
                onChange={(e) => setRepeat(e.target.value)}
              >
                <option value="">Select Repeat Frequency</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </Input>
            </FormGroup>

            <ModalFooter>
              <Button color="primary" type="submit">
                Schedule
              </Button>
              <Button color="secondary" onClick={toggleScheduleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>

      <ToastContainer autoClose={3000} limit={1} draggable pauseOnHover />

    {/* File Search Section */}
    <div className="my-4">
      <h5 className="fw-bold mb-3">Search File Transfer Data by Process ID</h5>
      <Input
        type="text"
        placeholder="Enter Process ID"
        value={fileSearchQuery}
        onChange={(e) => setFileSearchQuery(e.target.value)}
        style={{ width: "300px", display: "inline-block", marginRight: "10px" }}
      />
      <Button color="primary" onClick={handleFileSearch}>Search</Button>

      {fileSearchError && (
        <div className="alert alert-danger mt-3" role="alert">
          {fileSearchError}
        </div>
      )}

      {showFileResults && (
        <div className="mt-4">
          <Grid
            columns={fileColumns}
            data={fileTransferData}
            search={{
              placeholder: "Search File Transfer Data",
            }}
            sort
            pagination={{
              enabled: true,
              limit: 10,
            }}
          />
        </div>
      )}
    </div>
      <h5 className="fw-bold mb-3">Processes Data</h5>
      <ToastContainer autoClose={3000} limit={1} draggable pauseOnHover />

      {showProcessError && (
        <RbAlert variant="danger" onClose={() => setShowProcessError(false)} dismissible>
          {processErrorMsg}
        </RbAlert>
      )}

      <Grid
        columns={processColumns}
        data={processData}
        search={{
          placeholder: "Search Process Data",
        }}
        sort
        pagination={{
          enabled: true,
          limit: 10,
        }}
      />
    </div>
  );
};

export default Processes;
